<template>
  <div class="auto-info-undefined auto-info-undefined__wrapper">
    <div class="auto-info-undefined__write-plate-number">
      <div class="auto-info-undefined__icon">
        <svg-icon name="nocar"></svg-icon>
      </div>
      <div class="auto-info-undefined__text">
        {{ autoUndefinedText }}
      </div>
      <ButtonBase color="blue" size="sm-md" @click.native="openModal">
        {{ $t('dashboard.enter_plate_number') }}
      </ButtonBase>
    </div>
    <Popup :dialog.sync="modal">
      <div
        class="auto-info-undefined__modal"
        @click="isFocusMultipleField = false"
      >
        <div class="auto-info-undefined__icon">
          <svg-icon name="nocar"></svg-icon>
        </div>
        <Heading level="4" class="auto-info-undefined__modal-title">
          {{ autoUndefinedText }}
        </Heading>
        <div class="auto-info-undefined__modal-text">
          {{ $t('dashboard.enter_plate_number_to_detect') }}
        </div>
        <MultipleField
          v-model="carNumber"
          :items="countries"
          :is-focus="isFocusMultipleField"
          class="auto-info-undefined__modal-multiple-field"
          :error="
            ($v.carNumber.value.$dirty && !$v.carNumber.value.required) ||
            ($v.carNumber.value.$dirty && $v.carNumber.value.$invalid)
          "
          :error-txt="carNumberErrorText"
          @click.native.stop
          @focus="clearCarNumberField"
          @blur="blurCarNumberHandler"
        />
        <div class="auto-info-undefined__modal-buttons">
          <ButtonBase color="white" size="sm-md" @click.native="closeModal">
            {{ $t('popup.cancel') }}
          </ButtonBase>
          <ButtonBase
            color="blue"
            size="sm-md"
            :disabled="isDisabledModalBtn"
            @click.native="determineCarNumber"
          >
            <template v-if="!loadingModalBtn">
              {{ $t('popup.detect') }}
            </template>
            <Loader v-else color="white" size="sm" />
          </ButtonBase>
        </div>
      </div>
    </Popup>

    <div class="auto-info-undefined__information information-undefined-auto">
      <div class="auto-info__first-row">
        <div class="information-undefined-auto__content">
          <div class="auto-info-undefined__number">
            <img :src="`/images/flags/default-flag.svg`" alt="" />
            <span class="auto-info-undefined__number-text">{{
              defaultValues.autoPlateNumber
            }}</span>
          </div>
          <div class="auto-info-undefined__row">
            <div class="auto-info-undefined__values-wrap">
              <div class="auto-info-undefined__values">
                {{ $t('dashboard.date') }}
              </div>
              {{ defaultValues.date }}
            </div>
            <div class="auto-info-undefined__values-wrap">
              <div class="auto-info-undefined__values">
                {{ $t('dashboard.time') }}
              </div>
              {{ defaultValues.timeSlot }}
            </div>
          </div>
          <div class="auto-info-undefined__row">
            <div class="auto-info-undefined__values-wrap">
              <div class="auto-info-undefined__values">
                {{ $t('dashboard.brand') }}
              </div>
              {{ defaultValues.carBrand }}
            </div>
          </div>
          <div class="auto-info-undefined__row">
            <div class="auto-info-undefined__values-wrap">
              <div class="auto-info-undefined__values">
                {{ $t('dashboard.driver') }}
              </div>
              {{ defaultValues.driverName }}
            </div>
          </div>
        </div>
        <div class="information-undefined-auto__content">
          <div
            class="information-undefined-auto__not-found-status"
            :class="statusCssClasses"
          >
            {{ statusText }}
          </div>
          <div class="auto-info-undefined__row">
            <div class="auto-info-undefined__values-wrap">
              <div class="auto-info-undefined__values">
                {{ $t('dashboard.type_of_auto_title') }}
              </div>
              {{ defaultValues.typeAuto }}
            </div>
          </div>
          <div class="auto-info-undefined__row">
            <div class="auto-info-undefined__values-wrap">
              <div class="auto-info-undefined__values">
                {{ $t('dashboard.model') }}
              </div>
              {{ defaultValues.carModel }}
            </div>
          </div>
          <div class="auto-info-undefined__row">
            <div class="auto-info-undefined__values-wrap">
              <div class="auto-info-undefined__values">
                {{ $t('dashboard.passangers') }}
              </div>
              {{ defaultValues.countOfPassengers }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, helpers } from 'vuelidate/lib/validators';
const carNumber = helpers.regex('alphaNum', /^[A-Za-z0-9]+$/);

import Popup from '@/elements/Popup/Popup.vue';
import Loader from '@/elements/Loader/Loader.vue';
import ButtonBase from '@/elements/Buttons/ButtonBase.vue';
import MultipleField from '@/elements/Inputs/MultipleField.vue';
import Heading from '@/elements/Heading/Heading.vue';

export default {
  components: {
    ButtonBase,
    Popup,
    Loader,
    Heading,
    MultipleField,
  },

  props: {
    cameraStatus: {
      type: String,
      required: true,
    },
  },

  validations: {
    carNumber: {
      value: {
        required,
        alphaNum: carNumber,
      },
    },
  },

  data() {
    return {
      defaultValues: {
        autoPlateNumber: '-- ---- --',
        carBrand: '------',
        carModel: '------ ',
        countOfPassengers: '-',
        typeAuto: '------',
        driverName: '------ ------ ------',
        date: '--.--.----',
        timeSlot: '--:--',
      },
      countries: [],
      carNumber: {
        country: {
          id: 223,
          iso: 'UA',
          iso3: 'UKR',
          name: 'UKRAINE',
          'number-code': '804',
          'phone-code': '380',
          'simple-name': 'Ukraine',
        },
        value: '',
      },
      isFocusMultipleField: false,
      modal: false,
      loadingModalBtn: false,
    };
  },

  computed: {
    autoUndefinedText() {
      return this.cameraStatus === 'camera_not_determined'
        ? this.$t('dashboard.not_found_plate_number')
        : this.$t('dashboard.waiting_plate_number');
    },
    statusText() {
      return this.cameraStatus === 'camera_not_determined'
        ? this.$t('dashboard.not_found_plate_number_status')
        : this.$t('dashboard.waiting_plate_number_status');
    },
    statusCssClasses() {
      return {
        waiting: this.cameraStatus === 'waiting',
        'not-determined': this.cameraStatus === 'camera_not_determined',
      };
    },
    isDisabledModalBtn() {
      return this.$v.$invalid;
    },
    carNumberErrorText() {
      if (!this.$v.carNumber.value.required) {
        return this.$t('input_validation.car_number.empty');
      }
      return this.$t('input_validation.car_number.alpha_num');
    },
  },

  watch: {
    modal(value) {
      if (!value) {
        this.resetModalForm();
      }
    },
  },

  mounted() {
    this.fetchAllCountries();
  },
  methods: {
    openModal() {
      this.modal = true;
    },
    closeModal() {
      this.modal = false;
    },
    clearCarNumberField() {
      this.isFocusMultipleField = true;
      this.$v.carNumber.value.$reset();
    },
    blurCarNumberHandler() {
      this.isFocusMultipleField = false;
      this.$v.carNumber.value.$touch();
    },
    resetModalForm() {
      this.carNumber = {
        country: {
          id: 223,
          iso: 'UA',
          iso3: 'UKR',
          name: 'UKRAINE',
          'number-code': '804',
          'phone-code': '380',
          'simple-name': 'Ukraine',
        },
        value: '',
      };
      this.$v.carNumber.value.$reset();
    },
    async determineCarNumber() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      const manualEnteredCarNumber = {
        'auto-registration-plate-number': this.carNumber.value.toUpperCase(),
        'country-id-of-auto-registration-plate': this.carNumber.country.id,
      };
      this.loadingModalBtn = true;

      await this.$store.dispatch(
        'manualSendSignalAboutArrivalAuto',
        manualEnteredCarNumber
      );
    },
    async fetchAllCountries() {
      const res = await this.$store.dispatch('getAllCountries');
      this.countries = res;
    },
  },
};
</script>

<style lang="sass" scoped>
.auto-info-undefined
  display: flex
  width: 100%
  justify-content: space-between
  gap: 50px
  margin-bottom: 42px

.auto-info-undefined__icon svg
  height: 48px
  width:50px

.auto-info-undefined__points
  font-size: 24px
  color: var(--primary-color-dark-grey-2)

.auto-info-undefined__write-plate-number
  display: flex
  flex-direction: column
  align-items: center
  justify-content: space-between
  width: 50%
  padding: 52px 160px
  border: 1px solid rgba(40, 59, 89, 0.3)
  border-radius: var(--primary-border-radius)
  box-shadow: 0px 32px 80px rgba(38, 55, 76, 0.05)

  @media screen and (max-width: 1199px)
    padding: 42px 60px
    width: 36%

.auto-info-undefined__icon .icon-nocar
  width: 45px
  height: 48px

.auto-info-undefined__information
  display: flex
  justify-content: space-between
  flex-direction: column
  width: 70%

  @media screen and (max-width: 1199px)
    width: 60%

.auto-info__first-row
  display: flex
  height: 100%
  gap: 70px
  justify-content: space-between

  @media screen and (max-width: 1199px)
    gap: 50px

.auto-info-undefined__text
  display: flex
  text-align: center
  font-size: 24px
  line-height: 32px
  color: var(--primary-color-dark-blue)
  padding: 15px 0 25px

.information-undefined-auto
  display: flex
  justify-content: space-between
  padding: 25px 0 0

.information-undefined-auto__content,
.information-undefined-auto__content--second
  display: flex
  flex-direction: column
  justify-content: space-between
  width: 50%
  height: 100%

.auto-info-undefined__values-wrap
  font-size: 24px
  color: var(--primary-color-dark-grey-2)

.auto-info-undefined__values
  font-size: 24px
  line-height: 32px

.auto-info-undefined__values
  font-size: 16px
  color: var(--primary-color-dark-blue)

.auto-info-undefined__empty
  height: 50px

.auto-info-undefined__number
  display: flex
  align-items: center
  gap: 20px
  width: 100%
  font-size: 32px
  color: var(--primary-color-dark-grey-2)
  margin-bottom: 18px

.auto-info-undefined__number img
  width: 40px
  height: 27px

.auto-info-undefined__row
  display: flex
  justify-content: space-between
  margin-bottom: 15px

.information-undefined-auto__not-found-status
  display: flex
  align-items: center
  justify-content: center
  height: 40px
  width: 100%
  font-weight: 600
  font-size: 18px
  margin-bottom: 14px

.information-undefined-auto__not-found-status.not-determined
  background-color: rgba(255, 0, 0, 0.08)
  color: var(--primary-color-error)

.information-undefined-auto__not-found-status.waiting
  background-color: rgba(214, 160, 19, 0.08)
  color: var(--primary-color-warning)

.auto-info-undefined__modal
  display: flex
  flex-direction: column
  align-items: center
  height: 550px

  @media screen and (max-width: 1199px)
    height: 500px


.auto-info-undefined__modal-title
  margin: 20px 0 16px

.auto-info-undefined__modal-text
  font-size: 24px
  margin-bottom: 24px

.auto-info-undefined__modal-multiple-field
  margin-bottom: 80px

.auto-info-undefined__modal-buttons
  position: relative
  display: flex
  gap: 32px

.auto-info-undefined__modal-buttons:before
  position: absolute
  content: ''
  width: 100%
  height: 1px
  top: -40px
  left: 0
  background-color: var(--primary-color-light-grey-2)
</style>
