<template>
  <div class="control control__wrapper">
    <Heading level="4" class="control__subtitle-control">{{
      $t('dashboard.control')
    }}</Heading>
    <div class="control__menu">
      <div v-for="item in navMenu" :key="item.name" class="control__menu-item">
        <ButtonDashboard
          class="control__btn"
          @click.native="goToHandler(item.name)"
        >
          <div class="control__icon-text">
            <svg-icon :name="item.title" class="control__icons"></svg-icon>
            <span class="control__btn-text">
              {{ $t(`dashboard.${item.title}`) }}
            </span>
          </div>
          <svg-icon name="right-arrow" class="control__right-arrow"></svg-icon>
        </ButtonDashboard>
      </div>
    </div>

    <Heading level="4">{{ $t('dashboard.current_car') }}</Heading>
    <template v-if="cameraInfo && !loading">
      <CurrentAuto
        v-if="isCurrentAutoAvailable"
        :current-auto="currentAutoTicket"
        :camera-status="cameraStatus"
        :auto-plate-number="autoPlateNumber"
        :country-auto-registration-plate="countryAutoRegistrationPlate"
      />
      <CurrentAutoUndefined v-else :camera-status="cameraStatus" />
    </template>
    <Loader v-else />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CurrentAuto from '@/components/CurrentAuto/CurrentAuto.vue';
import CurrentAutoUndefined from '@/components/CurrentAuto/CurrentAutoUndefined.vue';

import ButtonDashboard from '@/elements/Buttons/ButtonDashboard.vue';
import Heading from '@/elements/Heading/Heading.vue';
import Loader from '@/elements/Loader/Loader.vue';

export default {
  components: {
    CurrentAuto,
    CurrentAutoUndefined,
    ButtonDashboard,
    Heading,
    Loader,
  },

  data() {
    return {
      navMenu: [
        {
          title: 'selling',
          name: 'new-ticket',
        },
        {
          title: 'queue',
          name: 'queue',
        },
        {
          title: 'barriers',
          name: 'barriers',
        },
        {
          title: 'scoreboards',
          name: 'scoreboards',
        },
      ],
      timerId: null,
      cameraInfo: null,
      loading: false,
    };
  },

  computed: {
    cameraStatus() {
      return this.cameraInfo?.status;
    },
    autoPlateNumber() {
      return this.cameraInfo?.auto_registration_plate_number;
    },
    countryAutoRegistrationPlate() {
      return this.cameraInfo?.country_of_auto_registration_plate;
    },
    isCurrentAutoAvailable() {
      return (
        this.cameraStatus === 'ticket_found' ||
        this.cameraStatus === 'ticket_not_found'
      );
    },
    currentAutoTicket() {
      return this.cameraInfo?.ticket;
    },
  },

  watch: {
    cameraStatus(newCameraStatus, oldCameraStatus) {
      this.trackChangingData(newCameraStatus, oldCameraStatus);
    },
    autoPlateNumber(newAutoPlateNumber, oldAutoPlateNumber) {
      this.trackChangingData(newAutoPlateNumber, oldAutoPlateNumber);
    },
  },

  mounted() {
    this.timerId = setInterval(async () => {
      const res = await this.$store.dispatch('getCameraInfo');
      this.cameraInfo = res;
    }, 1000);
  },

  destroyed() {
    clearInterval(this.timerId);
  },

  methods: {
    trackChangingData(newValue, oldValue) {
      if (oldValue && newValue !== oldValue) {
        this.showLoader();
      }
    },
    showLoader() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
    goToHandler(name) {
      this.$router.push(name);
    },
  },
};
</script>

<style lang="sass" scoped>
.control__menu
  display: flex
  justify-content: space-between
  gap: 20px
  padding-bottom: 48px
  border-bottom: 1px solid var(--primary-color-light-grey-2)
  @media screen and (max-width: 1199px)
    padding-bottom: 35px

.control__menu-item
  display: flex

.control__icon-text
  display: flex
  align-items: center

.control__btn
  display: flex
  justify-content: space-between
  align-items: center
  fill: var(--primary-color-dark-blue)

.control__btn-text
  padding-left: 17px

.control__btn:hover *
  fill: var(--btn-color-blue)

.control__icons
  width: 23px
  transition: var(--transition-speed)

.control__right-arrow
  height: 14px
  width: 9px

.control__right-arrow
  @media screen and (max-width: 1199px)
    display: none

.line
  height: 1px
  background-color: var(--primary-color-light-grey-2)

.control__subtitle-control
  margin-top: 24px

.control__subtitle
  margin-top: 32px
  line-height: 32px
</style>
